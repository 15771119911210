import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown, ChevronUp, Pencil, Trash2 } from "lucide-react";
import TeacherModal from "../../modals/admin/TeacherModal"; // Import the new modal component

const Teachers = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [teachers, setTeachers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  useEffect(() => {
    fetch("/dummyData.json")
      .then((response) => response.json())
      .then((data) => setTeachers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const onSubmit = (data) => {
    let teacherData;
  
    if (isEdit) {
      const existingTeacher = teachers.find(teacher => teacher.id === currentTeacherId);
  
      teacherData = {
        ...data,
        id: currentTeacherId,
        picture: data.picture && data.picture.length > 0 
          ? URL.createObjectURL(data.picture[0])  // If a new picture is uploaded, use it
          : existingTeacher.picture, // Otherwise, keep the old picture path from the JSON
      };
  
      const updatedTeachers = teachers.map((teacher) =>
        teacher.id === currentTeacherId ? teacherData : teacher
      );
      setTeachers(updatedTeachers);
      toast.success("Teacher updated successfully!");
    } else {
      teacherData = {
        ...data,
        id: teachers.length + 1,
        picture: data.picture && data.picture.length > 0 
          ? URL.createObjectURL(data.picture[0]) 
          : null,
      };
      setTeachers([...teachers, teacherData]);
      toast.success("Teacher added successfully!");
    }
  
    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };
  
  
  const handleEdit = (id) => {
    const teacher = teachers.find((teacher) => teacher.id === id);
  
    // Prepopulate form fields with the selected teacher's data
    setValue("username", teacher.username);
    setValue("email", teacher.email);
    setValue("phone", teacher.phone);
    setValue("password", teacher.password);
    setValue("location", teacher.location);
    setValue("dob", teacher.dob);
    setValue("gender", teacher.gender);
    setValue("joining_date", teacher.joining_date);
    setValue("combinations", teacher.combinations);
    setValue("job_nature", teacher.job_nature);
    setValue("cnic", teacher.cnic);
  
    // No need to set file input for the picture, just retain the picture URL
    setCurrentTeacherId(id);
    setIsEdit(true);
    setIsModalOpen(true);
  };
  
  
  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };
  

 

  const handleDelete = (id) => {
    const updatedTeachers = teachers.filter((teacher) => teacher.id !== id);
    setTeachers(updatedTeachers);
    toast.success("Teacher deleted successfully!");
  };

  const filterTeachers = (teachers, query) => {
    if (!query) {
      return teachers;
    }
    return teachers.filter((teacher) => {
      const usernameMatch = teacher.username
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = teacher.id && teacher.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredTeachers = filterTeachers(teachers, searchQuery);
  const totalPages = Math.ceil(teachers.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentTeachers = filteredTeachers.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-6 border border-[#241763]">
      <div className="flex justify-between mb-2">
        <h2 className="text-lg font-semibold">Total Teachers: {teachers.length}</h2>
      </div>
      <div className="flex justify-between mb-4 space-x-2 border-1 border-[#241763] p-2">
        <input
          type="text"
          placeholder="Search by name or ID"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(1);
          }}
          className="w-3/4 p-2 border rounded"
        />
        <button
          className="w-1/4 bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
            reset();
          }}
        >
          Add Teacher
        </button>
      </div>

      <TeacherModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        currentPicture={isEdit && teachers.find(t => t.id === currentTeacherId)?.picture}
      />

      <table className="table-auto w-full mt-6 ">
        <thead className="py-4 border-b font-bold">
          <tr>
            <th>Profile</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Joining Date</th>
            <th>Phone</th>
            <th>Job Nature</th>
            <th>CNIC</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm ">
          {currentTeachers.map((teacher, index) => (
            <>
              <tr key={teacher.id} className="p-2 py-2 hover:bg-gray-200">
                <td>
                  <img
                    src={teacher.picture}
                    alt="Teacher Profile"
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td>{teacher.username}</td>
                <td>{teacher.gender}</td>
                <td>{teacher.email}</td>
                <td>{teacher.joining_date}</td>
                <td className="text-blue-500">{teacher.phone}</td>
                <td>{teacher.job_nature}</td>
                <td>{teacher.cnic}</td>
                <td className="space-x-2 flex items-center py-2">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                    onClick={() => handleEdit(teacher.id)}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDelete(teacher.id)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>

                  <div
                    onClick={() => toggleView(index)}
                    className="cursor-pointer"
                  >
                    {expandedRowIndex === index ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </div>
                </td>
              </tr>
              {expandedRowIndex === index && (
                <tr>
                  <td colSpan="8">
                    <div className="bg-gray-100 p-4">
                      <p>
                        <strong>Location:</strong> {teacher.location}
                      </p>
                      <p>
                        <strong>DOB:</strong> {teacher.dob}
                      </p>
                      <p>
                        <strong>Combinations:</strong> {teacher.combinations}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center mt-4 bg-white border-t border-[#241763] p-2">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`px-3 py-1 mx-1 border rounded ${
              currentPage === i + 1 ? "bg-[#241763] text-white" : "bg-gray-200"
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Teachers;
