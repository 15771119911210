import React from "react";
import { feesHeadings, feesData } from "../../Constant/grades";
 import {users as userData} from '../../Constant/users'; 
import { useParentContext } from "../../ContextAPI/ParentContext";
import FeesTable from "../../Components/parent/FeesTable";
import ChildCards from "../../Components/parent/ChildCards";
import { useSelector } from "react-redux";
const Fees = () => {
  const users = useSelector((state) => state.parent.users);
  // const { users } = useParentContext();
  // const newData = feesData.map((data, index) => ({
  //   ...data,
  //   name: users[index].name,
  //   avatar: users[index].avatar,
  // }));
  const activeUser = users.find((user) => user.isActive === true);
   const table = activeUser.fees;
  
   const newData = table.map((item,index)=>({
    ...item,
    avatar: userData[index].avatar
   }))
 console.log(newData);
  return (
    <>
     <ChildCards />
  
    <div className="container-fluid shadow px-4 mt-4 py-3 bg-white rounded">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center fw-bold py-4">List of Student Fees</h4>
        </div>
      </div>
     
      <div className="row mt-3">
        <FeesTable headings={feesHeadings} data={newData} />
      </div>
    </div>
    </>
  );
};

export default Fees;
