import React from "react";
import "react-toastify/dist/ReactToastify.css";

const CourseModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
}) => {
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-md w-1/2">
        <h2 className="text-2xl mb-4">
          {isEdit ? "Edit Course" : "Add Course"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <input
              {...register("title")}
              placeholder="Title"
              className="p-2 border rounded"
            />
            <input
              {...register("code")}
              placeholder="Code"
              className="p-2 border rounded"
            />
            <input
              {...register("credit")}
              type="number"
              placeholder="Credit"
              className="p-2 border rounded"
            />
            <input
              {...register("summary")}
              placeholder="Summary"
              className="p-2 border rounded"
            />
            <input
              {...register("program")}
              type="number"
              placeholder="Program"
              className="p-2 border rounded"
            />
            <input
              {...register("level")}
              placeholder="Level"
              className="p-2 border rounded"
            />
            <input
              {...register("year")}
              type="number"
              placeholder="Year"
              className="p-2 border rounded"
            />
            <input
              {...register("semester")}
              placeholder="Semester"
              className="p-2 border rounded"
            />
            <select {...register("is_elective")} className="p-2 border rounded">
              <option value={true}>Elective</option>
              <option value={false}>Core</option>
            </select>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-green-500 text-white p-2 rounded"
            >
              {isEdit ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              className="bg-red-500 text-white p-2 ml-4 rounded"
              onClick={() => {
                setIsModalOpen(false);
                reset();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseModal;
