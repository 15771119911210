import React from 'react';
import Card from '../../Components/admin/Cards/Cards';
import LineChartComponent from '../../Components/admin/Cards/LineChartComponent';
import AreaChartComponent from '../../Components/admin/Cards/AreaChartComponent';
import AcademicResultsChart from '../../Components/admin/Cards/AcademicResultsChart';
import AttendanceChart from '../../Components/admin/Cards/AttendanceChart';
import ParentSatisfactionChart from '../../Components/admin/Cards/ParentSatisfactionChart';
import TeacherObservationChart from '../../Components/admin/Cards/TeacherObservationChart';
import TeacherSatisfactionChart from '../../Components/admin/Cards/TeacherSatisfactionChart';
import VolumesChart from '../../Components/admin/Cards/VolumesChart';
import FinancialChart from '../../Components/admin/Cards/FinancialChart';
import { chartData } from '../../Constant/charts';
import { cardData} from '../../Constant/cards';

const Dashboard = () => {
  return (
    <div className="p-6">
      {/* Top Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            value={card.value}
            percent={card.percent}
            color={card.color}
            progressValue={card.progressValue}
            increase={card.increase}
          />
        ))}
      </div>

      {/* Chart Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <LineChartComponent data={chartData} />
        <AreaChartComponent data={chartData} />
        <FinancialChart/>
        <VolumesChart/>
        <AttendanceChart/>
        <AcademicResultsChart/>
        <TeacherObservationChart/>
        <TeacherSatisfactionChart/>
        <TeacherSatisfactionChart/>
      </div>
    </div>
  );
};

export default Dashboard;
