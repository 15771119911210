import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import StudentModal from "../../modals/admin/StudentModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown, ChevronUp, Pencil, Trash2 } from "lucide-react";

const Students = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [students, setStudents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  useEffect(() => {
    fetch("/dummyStdData.json")
      .then((response) => response.json())
      .then((data) => setStudents(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const onSubmit = (data) => {
    let studentData;

    if (isEdit) {
      const existingStudent = students.find(
        (student) => student.id === currentStudentId
      );
      studentData = {
        ...data,
        id: currentStudentId,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : existingStudent.picture, // If a new picture is uploaded, use it
      };
      const updatedStudents = students.map((student) =>
        student.id === currentStudentId ? studentData : student
      );
      setStudents(updatedStudents);
      toast.success("Students updated successfully!");
    } else {
      studentData = {
        ...data,
        id: students.length + 1,
        picture:
          data.picture && data.picture.length > 0
            ? URL.createObjectURL(data.picture[0])
            : null,
      };
      setStudents([...students, studentData]);
      toast.success("Students added successfully!");
    }
    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const student = students.find((student) => student.id === id);
    setValue("username", student.username);
    setValue("email", student.email);
    setValue("phone", student.phone);
    setValue("password", student.password);
    setValue("location", student.location);
    setValue("dob", student.dob);
    setValue("gender", student.gender);
    setValue("standard", student.standard);
    setValue("section", student.section);
    setValue("admission_date", student.admission_date);

    setIsEdit(true);
    setIsModalOpen(true);
    setCurrentStudentId(id);
  };

  const handleDelete = (id) => {
    const updatedStudents = students.filter((student) => student.id !== id);
    setStudents(updatedStudents);
    toast.success("Student deleted successfully!");
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const filterStudents = (students, query) => {
    if (!query) {
      return students;
    }
    return students.filter((student) => {
      const usernameMatch = student.username
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = student.id && student.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredStudents = filterStudents(students, searchQuery);
  const totalPages = Math.ceil(students.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-6 border border-[#241763]">
      <div className="flex justify-between mb-2">
        <h2 className="text-lg font-semibold">
          Total Students: {students.length}
        </h2>
      </div>
      <div className="flex justify-between mb-4 space-x-2 border-1 border-[#241763] p-2">
        <input
          type="text"
          placeholder="Search by name or ID"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(1);
          }}
          className="w-3/4 p-2 border rounded"
        />
        <button
          className="w-1/4 bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
          }}
        >
          Add Student
        </button>
      </div>

      <StudentModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        currentPicture={
          isEdit && students.find((t) => t.id === currentStudentId)?.picture
        }
      />

      <table className="table-auto w-full mt-6">
        <thead className="py-4 border-b font-bold">
          <tr>
           <th>Profile</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Admission Date</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {currentStudents.map((student, index) => (
            <>
              <tr key={student.id} className="p-2 hover:bg-gray-200">
                <td>
                  <img
                    src={student.picture}
                    alt="student Profile"
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td>{student.username}</td>
                <td>{student.gender}</td>
                <td>{student.email}</td>
                <td>{student.admission_date}</td>
                <td className="text-blue-500">{student.phone}</td>
                <td className="space-x-2 flex items-center py-2">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                    onClick={() => handleEdit(student.id)}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDelete(student.id)}
                  >
                    <Trash2 className="text-[#241763]  w-4 h-4 group-hover:text-white" />
                  </button>
                  <div
                    onClick={() => toggleView(index)}
                    className="cursor-pointer"
                  >
                    {expandedRowIndex === index ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </div>
                </td>
              </tr>
              {expandedRowIndex === index && (
                <tr>
                  <td colSpan="6">
                    <div className="bg-gray-100 p-4">
                      <p>
                        <strong>Location:</strong> {student.location}
                      </p>
                      <p>
                        <strong>DOB:</strong> {student.dob}
                      </p>
                      <p>
                        <strong>Standard:</strong> {student.standard}
                      </p>
                      <p>
                        <strong>Section:</strong> {student.section}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center mt-4 bg-white border-t border-[#241763] p-2">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`px-3 py-1 mx-1 border rounded ${
              currentPage === i + 1 ? "bg-[#241763] text-white" : "bg-gray-200"
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Students;
