import React from "react";
import { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";

const StudentModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  currentPicture,
}) => {
  const [newPicture, setNewPicture] = useState(null);

  useEffect(() => {
    // Reset the new picture state when the modal opens
    if (isEdit) {
      setNewPicture(currentPicture); // Set initial picture if editing
    } else {
      setNewPicture(null); // Reset for adding a new teacher
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewPicture(imageUrl); // Update local state with the new picture URL
    }
  };
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-md w-1/2">
        <h2 className="text-2xl mb-4">
          {isEdit ? "Edit Student" : "Add Student"}
        </h2>
        {isEdit && currentPicture && (
          <div className="mb-4">
            <label className="block font-semibold mb-1">Current Picture:</label>
            <img
              src={newPicture || currentPicture} // Use new picture if available
              alt="Student Profile"
              className="w-20 h-20 rounded-full mb-4"
            />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="grid grid-cols-2 gap-4">
            <input
              {...register("username")}
              placeholder="Username"
              className="p-2 border rounded"
            />
            <input
              {...register("email")}
              placeholder="Email"
              className="p-2 border rounded"
            />
            <input
              {...register("phone")}
              placeholder="Phone"
              className="p-2 border rounded"
            />
            <input
              {...register("password")}
              type="password"
              placeholder="Password"
              className="p-2 border rounded"
            />
            <input
              {...register("location")}
              placeholder="Location"
              className="p-2 border rounded"
            />
            <input
              {...register("dob")}
              type="date"
              placeholder="Date of Birth"
              className="p-2 border rounded"
            />
            <select {...register("gender")} className="p-2 border rounded">
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <input
              {...register("standard")}
              placeholder="Standard"
              className="p-2 border rounded"
            />
            <input
              {...register("section")}
              placeholder="Section"
              className="p-2 border rounded"
            />
            <input
              {...register("admission_date")}
              type="date"
              placeholder="Admission Date"
              className="p-2 border rounded"
            />
            <div>
            <label className="block">
                {isEdit ? "Upload New Picture" : "Upload Picture"}
              </label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("picture")}
                required={!isEdit} // Only required in add mode
                onChange={handlePictureChange} // Update picture on change
              />
              </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-green-500 text-white p-2 rounded"
            >
              {isEdit ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              className="bg-red-500 text-white p-2 ml-4 rounded"
              onClick={() => {
                setIsModalOpen(false);
                reset();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StudentModal;