import React, { useState, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { postRequest } from "../../services/index";
import toast, { Toaster } from "react-hot-toast";
import { useUserContext } from "../../ContextAPI/useUserContext";
import ReactDatePicker from "../../Components/Common/ReactDatePicker";
import FlexBtn from "../../Components/Common/FlexBtn";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddLessonPlan = () => {

  const { userData, userLeacture } = useUserContext();
  const [userClasses, setUserClasses] = useState([]);
  const [userSections, setUserSections] = useState([]);
  const [userSubjects, setUserSubjects] = useState([]);
  const auth = JSON.parse(localStorage.getItem("user"));
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  

  const [lessonPlanData, setLessonPlanData] = useState({
    standard: "",
    section: "",
    subject: "",
    teacher: auth.userId,
    week_details: [],
    lecture_days: [],
  });
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  useEffect(() => {
    if (lessonPlanData.lecture_days.length > 0) {
      const newInputs = Array.from({ length: 1 }).map(() => ({
        week_details: lessonPlanData.lecture_days.map(() => ({
          topic: "",
          date: "",
          details: "",
          completion: "",
          ContentUpload: "",
          VideoUpload: "",
        })),
      }));
      setFormData(newInputs);
    }
  }, [lessonPlanData.lecture_days]);
  useEffect(() => {
    if (userData) {
      const classSet = new Set();
      userData.combinations.forEach((item) => {
        if (item.class) classSet.add(item.class);
      });
      setUserClasses(Array.from(classSet));
    }
  }, [userData]);
  console.log("userData",userData)
  const handleClassSelect = (standard) => {
    setLessonPlanData({
      ...lessonPlanData,
      standard,
      section: "",
      subject: "",
    });

    const sections = new Set();
    const subjects = new Set();
    userData.combinations.forEach((item) => {
      if (item.class === standard) {
        if (item.section) sections.add(item.section);
        if (item.subject) subjects.add(item.subject);
      }
    });

    setUserSections(Array.from(sections));
    setUserSubjects(Array.from(subjects));
  };

  const handleSectionSelect = (section) => {
    setLessonPlanData({ ...lessonPlanData, section });
  };

  const handleSubjectSelect = (subject) => {
    const selectedSubject = userLeacture
      .flatMap((lecture) => lecture.add_subjects)
      .find((sub) => sub.name === subject);

    setLessonPlanData((prev) => ({
      ...prev,
      subject,
      lecture_days: selectedSubject ? selectedSubject.days : [],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLessonPlanData({ ...lessonPlanData, [name]: value });
  };

  const handleChange = (field, value, weekIndex, dayIndex) => {
    const updatedFormData = [...formData];

    // Ensure week_details has enough weeks
    while (updatedFormData.length <= weekIndex) {
      updatedFormData.push({
        week_details: lessonPlanData.lecture_days.map(() => ({
          topic: "",
          date: "",
          details: "",
          completion: "",
          ContentUpload: "",
          VideoUpload: "",
        })),
      });
    }

    // Update the specific field in week_details
    updatedFormData[weekIndex].week_details[dayIndex] = {
      ...updatedFormData[weekIndex].week_details[dayIndex],
      [field]: value,
    };

    setFormData(updatedFormData);
  };


  const handleAddInputs = (e) => {
    e.preventDefault();

    const newInputs = {
      week_details: Array.from({ length: lessonPlanData.lecture_days.length }).map(() => ({
        topic: "",
        date: "",
        details: "",
        completion: "",
        ContentUpload: "",
        VideoUpload: "",
      })),
    };
    setFormData([...formData, newInputs]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Collect files to upload
    const fileUploads = [];

    formData.forEach((week, weekIndex) => {
      week.week_details.forEach(async (detail, dayIndex) => {
        if (detail.ContentUpload) {
          fileUploads.push({
            field: 'ContentUpload',
            file: detail.ContentUpload,
            weekIndex,
            dayIndex,
          });
        }
        if (detail.VideoUpload) {
          fileUploads.push({
            field: 'VideoUpload',
            file: detail.VideoUpload,
            weekIndex,
            dayIndex,
          });
        }
      });
    });

    // Upload files
    for (const upload of fileUploads) {
      const { field, file, weekIndex, dayIndex } = upload;
      const endpoint = field === 'ContentUpload' ? '/courses/upload/doc/' : '/courses/upload/vid/';
      const uploadData = new FormData();
      uploadData.append('file', file);

      try {
        const response = await postRequest(endpoint, uploadData, true);
        if (response.ok) {
          const data = await response.json();
          setFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[weekIndex].week_details[dayIndex][field] = data.file;
            return updatedFormData;
          });
          toast.success(`${field} uploaded successfully`);
        } else {
          toast.error(`Failed to upload ${field}`);
        }
      } catch (error) {
        console.error(`Error uploading ${field}:`, error);
        toast.error(`Error uploading ${field}`);
      }
    }

    // Submit lesson plan data
    const transformedData = formData.reduce((acc, current, index) => {
      acc[`week${index + 1}`] = current.week_details;
      return acc;
    }, {});

    const updatedLessonPlanData = {
      ...lessonPlanData,
      week_details: Object.values(transformedData)
    };

    await postLessonPlan(updatedLessonPlanData);
  };


  const postLessonPlan = async (lessonPlanData) => {
    try {
      const res = await postRequest("/courses/lesson_plan/", lessonPlanData);
      const data = await res.json();
      if (res.ok) {
        toast.success("Lesson plan created");
        navigate('/lesson-plan');

      } else {
        toast.error("Something went wrong, please try again.");
      }
    } catch (err) {
      toast.error("Server error, try again later.");
    }
  };

  return (
    <>
      <Toaster />
      <div className="bg-white pt-4 shadow-lg rounded-4 min-vh-82">
        <h2 className={`p-3 myClassesHeading ${isArabic === "sa" ? "text-end" : ""
          }`}>{t("CreateLeasonPlan")}</h2>
        <form className="p-3">
          <div className={`row studentProfileHeading ${isArabic === "sa" ? "flex-row-reverse" : ""
            }`}>
            <div className={`col-md-4 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
              <label htmlFor="classInput" className="weakform-text form-label">
                {t("SelectClass")}
              </label>
              <div className="mb-3 d-flex">
                <input
                  id="classInput"
                  type="text"
                  className="input-cx rounded-start ps-2"
                  value={lessonPlanData.standard}
                  onChange={handleInputChange}
                  name="standard"
                  readOnly
                />
                <button
                  className="drop-btn rounded-end"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ChevronDown className="icon-drop" />
                </button>
                <ul className="dropdown-menu">
                  {userClasses.map((userClass, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleClassSelect(userClass)}
                      >
                        {userClass}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={`col-md-4 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
              <div className="mb-3">
                <label
                  htmlFor="sectionInput"
                  className="weakform-text form-label"
                >
                  {t("SelectSection")}
                </label>
                <div className="mb-3 d-flex">
                  <input
                    id="sectionInput"
                    type="text"
                    className="input-cx rounded-start ps-2"
                    value={lessonPlanData.section}
                    onChange={handleInputChange}
                    name="section"
                    disabled={!lessonPlanData.standard}
                    readOnly
                  />
                  <button
                    className="drop-btn rounded-end"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    disabled={!lessonPlanData.standard}
                  >
                    <ChevronDown className="icon-drop" />
                  </button>
                  <ul className="dropdown-menu">
                    {!lessonPlanData.standard ? (
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          disabled
                        >
                          Please select Class first
                        </button>
                      </li>
                    ) : (
                      userSections.map((section, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleSectionSelect(section)}
                          >
                            {section}
                          </button>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className={`col-md-4 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
              <div className="mb-3">
                <label
                  htmlFor="subjectInput"
                  className="weakform-text form-label"
                >
                  {t("SelectSubject")}
                </label>
                <div className="mb-3 d-flex">
                  <input
                    id="subjectInput"
                    type="text"
                    name="subject"
                    className="input-cx rounded-start ps-2"
                    value={lessonPlanData.subject}
                    onChange={handleInputChange}
                    disabled={!lessonPlanData.standard}
                    readOnly
                  />
                  <button
                    className="drop-btn rounded-end"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    disabled={!lessonPlanData.standard}
                  >
                    <ChevronDown className="icon-drop" />
                  </button>
                  <ul className="dropdown-menu">
                    {!lessonPlanData.standard ? (
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          disabled
                        >
                          Please select Class first
                        </button>
                      </li>
                    ) : (
                      userSubjects.map((subject, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleSubjectSelect(subject)}
                          >
                            {subject}
                          </button>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* {formData.length !== 0 && (
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <button className="btn btn-primary" onClick={handleAddInputs}>
                  Add Week
                </button>
              </div>
            </div>
          )} */}
          <>
            {formData.map((input, index) => (
              <div key={index} className=" mb-4">
                {lessonPlanData.lecture_days.map((_, subIndex) => (
                  <div key={subIndex} className="row mb-2">
                    <div>
                      <h3 className="col-md-12 mt-3"> {`${lessonPlanData?.lecture_days[subIndex]?.day} (${lessonPlanData?.lecture_days[subIndex]?.start_time} - ${lessonPlanData?.lecture_days[subIndex]?.end_time})`}</h3>
                    </div>
                    <div className={`col-md-4 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                      <label
                        htmlFor={`topic-${index}-${subIndex}`}
                        className="weakform-text form-label"
                      >
                        {t("Topic")}
                      </label>
                      <div className="mb-3">
                        <input
                          id={`topic-${index}-${subIndex}`}
                          type="text"
                          className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                          value={input?.topic}
                          onChange={(e) =>
                            handleChange(
                              "topic",
                              e.target.value,
                              index,
                              subIndex
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className={`col-md-4 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                      <label
                        htmlFor={`date-${index}-${subIndex}`}
                        className="weakform-text form-label"
                      >
                        {t("Date")}
                      </label>
                      <div className="mb-3">
                        <ReactDatePicker
                          id={`date-${index}-${subIndex}`}
                          getDate={(name, date) =>
                            handleChange("date", date, index, subIndex)
                          }
                          name={`date-${index}-${subIndex}`}
                        />
                      </div>
                    </div>
                    <div className={`col-md-4 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                      <label
                        htmlFor={`completion-${index}-${subIndex}`}
                        className="weakform-text form-label"
                      >
                        {t("Completion")}
                      </label>
                      <div className="mb-3">
                        <input
                          id={`completion-${index}-${subIndex}`}
                          type="text"
                          className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                          name="completion"
                          // value={input?.subRows[subIndex]?.details}
                          onChange={(e) =>
                            handleChange(
                              "completion",
                              e.target.value,
                              index,
                              subIndex
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                      <label
                        htmlFor={`contentUpload-${index}-${subIndex}`}
                        className="weakform-text form-label"
                      >
                        {t("ContentUpload")}
                      </label>
                      <div className="mb-3">
                        <div className={`input-group  ${isArabic === "sa" ? "flex-row-reverse" : ""
                          }`}>
                          <label
                            htmlFor={`contentUpload-${index}-${subIndex}`}
                            className="btn btn-outline-secondary bgColor"
                          >
                            {t("ChooseFile")}
                          </label>
                          <input
                            type="file"
                            id={`contentUpload-${index}-${subIndex}`}
                            className="d-none"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              handleChange(
                                "ContentUpload",
                                file,
                                index,
                                subIndex
                              );
                            }}
                          />
                          <input
                            type="text"
                            className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                            value={
                              // Assuming you have a way to track the file name similar to VideoUpload
                              formData[index] && formData[index].week_details[subIndex] &&
                                formData[index].week_details[subIndex].ContentUpload
                                ? formData[index].week_details[subIndex].ContentUpload.name
                                : t("NoFileChosen")
                            }
                            readOnly
                          />
                        </div>
                      </div>

                    </div>
                    <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                      <label
                        htmlFor={`videoUpload-${index}-${subIndex}`}
                        className="weakform-text form-label"
                      >
                        {t("VideoUpload")}
                      </label>
                      <div className="mb-3">
                        <div className={`input-group  ${isArabic === "sa" ? "flex-row-reverse" : ""
                          }`}>
                          <label
                            htmlFor={`videoUpload-${index}-${subIndex}`}
                            className="btn btn-outline-secondary bgColor"
                          >
                            {t("ChooseFile")}
                          </label>
                          <input
                            type="file"
                            id={`videoUpload-${index}-${subIndex}`}
                            className="d-none"
                            onChange={(e) => {
                              handleChange("VideoUpload", e.target.files[0], index, subIndex);
                            }}
                          />
                          <input
                            type="text"
                            className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                            value={
                              formData[index] && formData[index].week_details[subIndex] &&
                                formData[index].week_details[subIndex].VideoUpload
                                ? formData[index].week_details[subIndex].VideoUpload.name
                                : t("NoFileChosen")
                            }
                            readOnly
                          />
                        </div>
                      </div>


                    </div>
                    <div className={`col-md-12 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                      <label
                        htmlFor={`details-${index}-${subIndex}`}
                        className="weakform-text form-label"
                      >
                        {t("Details")}
                      </label>
                      <div className="mb-3">
                        <textarea
                          id={`details-${index}-${subIndex}`}
                          rows='5'
                          type="text"
                          className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                          // value={input?.subRows[subIndex]?.details}
                          onChange={(e) =>
                            handleChange(
                              "details",
                              e.target.value,
                              index,
                              subIndex
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
          <div className="col-md-12   px-4 mt-3">
            <div className="d-flex   gap-3 justify-content-end">
              <Link to="/lesson-plan">
                <FlexBtn
                  label={t("Cancel")}
                  color="#463C77"
                  bgColor="#EDEBF1"
                />
              </Link>
              <span onClick={handleSubmit}>
                <FlexBtn
                  label={t("Create")}
                  color="white"
                  bgColor="#463C77"
                  style={{ fontSize: "14px" }}

                />
              </span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddLessonPlan;