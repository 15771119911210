import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { getRequest, postRequest } from "../services/index";
import toast, { Toaster } from "react-hot-toast";
import ReactDatePicker from "../Components/Common/ReactDatePicker";
import { useUserContext } from "../ContextAPI/useUserContext";
import { useTranslation } from "react-i18next";

const ScheduleClass = ({ onRequestClose }) => {
  const { userData, userLeacture } = useUserContext();
  const [userClasses, setUserClasses] = useState([]);
  const [userSections, setUserSections] = useState([]);
  const [userSubjects, setUserSubjects] = useState([]);
  const [filteredLesson, setFilteredLesson] = useState([]);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const [classData, setClassData] = useState({
    title: "",
    subject: "",
    section: "",
    class: "",
    day: "",
    date: null,
    time: "",
    lesson_plan: "",
  });

  const [loading, setLoading] = useState(false);

  // Fetch lesson plan data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest("/courses/lesson_plan/");
        const data = await response.json();
        setFilteredLesson(data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, []);

  // Set initial user classes from userData
  useEffect(() => {
    if (userData) {
      const classSet = new Set(userData.combinations.map(item => item.class));
      setUserClasses(Array.from(classSet));
    }
  }, [userData]);

  const handleClassSelect = (selectedClass) => {
    setClassData({ ...classData, class: selectedClass, section: "", subject: "" });

    const sections = new Set();
    const subjects = new Set();
    userData.combinations.forEach((item) => {
      if (item.class === selectedClass) {
        if (item.section) sections.add(item.section);
        if (item.subject) subjects.add(item.subject);
      }
    });

    setUserSections(Array.from(sections));
    setUserSubjects(Array.from(subjects));
  };

  const handleSectionSelect = (section) => {
    setClassData({ ...classData, section });
  };

  const handleSubjectSelect = (subject) => {
    const selectedSubject = userLeacture
      .flatMap((lecture) => lecture.add_subjects)
      .find((sub) => sub.name === subject);

    setClassData({ ...classData, subject });
  };

  const handleDateChange = (name, date) => {
    setClassData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const handleSubmitForm = async () => {
    const payload = {
      title: classData.title,
      subject: classData.subject,
      section: classData.section,
      class: classData.class,
      date: classData.date,
      time: classData.time,
      lesson_plan: classData.lesson_plan,
    };

    try {
      setLoading(true);
      const res = await postRequest("/quiz/class-schedule/", payload);
      if (res.ok) {
        toast.success("Class added successfully");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      toast.error("Server error, try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid p-0 m-0 pb-4 modalWrapper">
      <Toaster />
      <div className={` row d-flex justify-content-center p-0 m-0 ${isArabic === "sa" ? "flex-row-reverse" : ""
            }`}>
        <div className="col-md-12 examModalWrapper p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-4 col-md-12 examModalHeader">
            <h4 style={{ color: "#060317" }} className="fw-bold">
              {t("classSchedule")}
            </h4>
            <div
              className="iconWrapper cursor-pointer"
              onClick={onRequestClose}
            >
              <X />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 px-3">
        <div className={`col-md-12 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
            <label htmlFor="examTitle">{t("Title")}</label>
            <input
              type="text"
              className={`form-control  fs-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
              id="examTitle"
              name="title"
              value={classData.title}
              onChange={(e) => setClassData({ ...classData, title: e.target.value })}
            />
        </div>
        <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
            <label htmlFor="classSelect">{t("SelectClass")} </label>
            <select
              className={`form-control  fs-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
              id="classSelect"
              name="class"
              value={classData.class}
              onChange={(e) => handleClassSelect(e.target.value)}
            >
              <option value="">{t("SelectClass")}</option>
              {userClasses.map((cls, index) => (
                <option key={index} value={cls}>
                  {cls}
                </option>
              ))}
            </select>
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="subjectSelect">{t("SelectSubject")}</label>
            <select
              className={`form-control  fs-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
              id="subjectSelect"
              name="subject"
              value={classData.subject}
              onChange={(e) => handleSubjectSelect(e.target.value)}
              disabled={!classData.class}
            >
              <option value="">{t("SelectSubject")}</option>
              {userSubjects.map((subject, index) => (
                <option key={index} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
          <div className="d-flex flex-column gap-2">
            <label htmlFor="sectionSelect">{t("SelectSection")}</label>
            <select
              className={`form-control  fs-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
              id="sectionSelect"
              name="section"
              value={classData.section}
              onChange={(e) => handleSectionSelect(e.target.value)}
              disabled={!classData.class}
            >
              <option value="">{t("SelectSection")}</option>
              {userSections.map((section, index) => (
                <option key={index} value={section}>
                  {section}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            <label htmlFor="date">{t("Date")}</label>
            <ReactDatePicker
              className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
              selectedDate={classData.date}
              getDate={(date) => handleDateChange("date", date)}
              name="date"
            />
          </div>
        </div>
        <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
            <label htmlFor="date">{t("Day")}</label>
            <input
              type="Day"
              className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
              name="day"
              value={classData.day}
              onChange={(e) => setClassData({ ...classData, day: e.target.value })}
            />
        </div>
        <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
          <label htmlFor="time">{t("Time")}</label>
          <input
            type="time"
            className="form-control"
            value={classData.time}
            onChange={(e) => setClassData({ ...classData, time: e.target.value })}
            name="time"
          />
        </div>
        <div className={`col-md-6 mt-3 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
          <label htmlFor="lessonPlan">{t("SelectLessonPlan")}</label>
          <select
            className={`form-control  fs-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
            id="lessonPlan"
            name="lesson_plan"
            value={classData.lesson_plan}
            onChange={(e) => setClassData({ ...classData, lesson_plan: e.target.value })}
          >
            <option value="">{t("SelectLessonPlan")}</option>
            {filteredLesson
              .filter(
                (lesson) =>
                  lesson.standard === classData.class &&
                  lesson.subject === classData.subject
              )
              .flatMap((lesson) => lesson.week_details)
              .flatMap((week) => week).length === 0 ? (
              <option value="">{t("Nodataavailable")}</option>
            ) : (
              filteredLesson
                .filter(
                  (lesson) =>
                    lesson.standard === classData.class &&
                    lesson.subject === classData.subject
                )
                .flatMap((lesson) => lesson.week_details)
                .flatMap((week) => week)
                .map((item, index) => (
                  <option key={index} value={item.topic}>
                    {item.topic}
                  </option>
                ))
            )}
          </select>
        </div>
      </div>
      <div className="row px-4 py-2">
        <div className="col-md-12 d-flex justify-content-center justify-content-md-end">
          <div className="row">
            <div className="col-md-6 mt-2">
              <button
                className="btnFooter"
                style={{ backgroundColor: "#EDEBF1", color: "#463C77" }}
                onClick={onRequestClose}
              >
                {t("Cancel")}
              </button>
            </div>
            <div className="col-md-6 mt-2">
              <button
                className="btnFooter bgMain"
                style={{ color: "white" }}
                onClick={handleSubmitForm}
              >
                {loading ? "Loading..." : t("Create")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleClass;
