import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CourseModal from "../../modals/admin/CourseModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown, ChevronUp, Pencil, Trash2 } from "lucide-react";

const Courses = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [courses, setCourses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  useEffect(() => {
    fetch("/dummyCourseData.json")
      .then((response) => response.json())
      .then((data) => setCourses(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const onSubmit = (data) => {
    if (isEdit) {
      const updatedCourses = courses.map((course) =>
        course.id === currentCourseId
          ? { ...course, ...data, id: currentCourseId }
          : course
      );
      setCourses(updatedCourses);
      toast.success("Course updated successfully!");
    } else {
      setCourses([...courses, { ...data, id: courses.length + 1 }]);
      toast.success("Course added successfully!");
    }
    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const course = courses.find((course) => course.id === id);
    setValue("title", course.title);
    setValue("code", course.code);
    setValue("credit", course.credit);
    setValue("summary", course.summary);
    setValue("program", course.program);
    setValue("level", course.level);
    setValue("year", course.year);
    setValue("semester", course.semester);
    setValue("is_elective", course.is_elective);

    setIsEdit(true);
    setIsModalOpen(true);
    setCurrentCourseId(id);
  };

  const handleDelete = (id) => {
    const updatedCourses = courses.filter((course) => course.id !== id);
    setCourses(updatedCourses);
    toast.success("Course deleted successfully!");
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const filterCourses = (courses, query) => {
    if (!query) {
      return courses;
    }
    return courses.filter((course) => {
      const titleMatch = course.title
        .toLowerCase()
        .includes(query.toLowerCase());
      const codeMatch = course.code.toLowerCase().includes(query.toLowerCase());
      return titleMatch || codeMatch;
    });
  };

  const filteredCourses = filterCourses(courses, searchQuery);
  const totalPages = Math.ceil(courses.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentCourses = filteredCourses.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-6 border border-[#241763]">
      <div className="flex justify-between mb-2">
        <h2 className="text-lg font-semibold">
          Total Courses: {courses.length}
        </h2>
      </div>
      <div className="flex justify-between mb-4 space-x-2 border-1 border-[#241763] p-2">
        <input
          type="text"
          placeholder="Search by title or code"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(1);
          }}
          className="w-3/4 p-2 border rounded"
        />
        <button
          className="w-1/4 bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsModalOpen(true);
            setIsEdit(false);
          }}
        >
          Add Course
        </button>
      </div>

      <CourseModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
      />

      <table className="table-auto w-full mt-6">
        <thead className="py-4 border-b font-bold">
          <tr>
            <th>Title</th>
            <th>Code</th>
            <th>Credit</th>
            <th>Level</th>
            <th>Year</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {currentCourses.map((course, index) => (
            <>
              <tr key={course.id} className="p-2 hover:bg-gray-200">
                <td>{course.title}</td>
                <td>{course.code}</td>
                <td>{course.credit}</td>
                <td>{course.level}</td>
                <td>{course.year}</td>
                <td className="space-x-2 flex items-center py-2">
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                    onClick={() => handleEdit(course.id)}
                  >
                    <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <button
                    className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                    onClick={() => handleDelete(course.id)}
                  >
                    <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                  </button>
                  <div
                    onClick={() => toggleView(index)}
                    className="cursor-pointer"
                  >
                    {expandedRowIndex === index ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </div>
                </td>
              </tr>
              {expandedRowIndex === index && (
                <tr>
                  <td colSpan="6">
                    <div className="bg-gray-100 p-4">
                      <p>
                        <strong>Summary:</strong> {course.summary}
                      </p>
                      <p>
                        <strong>Program:</strong> {course.program}
                      </p>
                      <p>
                        <strong>Level:</strong> {course.level}
                      </p>
                      <p>
                        <strong>Year:</strong> {course.year}
                      </p>
                      <p>
                        <strong>Semester:</strong> {course.semester}
                      </p>
                      <p>
                        <strong>Elective:</strong>{" "}
                        {course.is_elective ? "Yes" : "No"}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center mt-4 bg-white border-t border-[#241763] p-2">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`px-3 py-1 mx-1 border rounded ${
              currentPage === i + 1 ? "bg-[#241763] text-white" : "bg-gray-200"
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Courses;
