import React from 'react';
import { BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { volumesData } from '../../../Constant/charts';

const VolumesChart = () => {
  return (
    <div className="bg-white shadow-md p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Volumes</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={volumesData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="learners" fill="#8884d8" />
          <Bar dataKey="newAdmission" fill="#82ca9d" />
          <Bar dataKey="dropOut" fill="#FF8042" />
          <Bar dataKey="teachers" fill="#FFBB28" />
          <Line dataKey="turnover" stroke="#000" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VolumesChart;
