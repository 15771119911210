import React, { useState, useEffect } from "react";

const TeacherModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  currentPicture, // Pass the current picture when editing
}) => {
  const [newPicture, setNewPicture] = useState(null);

  useEffect(() => {
    // Reset the new picture state when the modal opens
    if (isEdit) {
      setNewPicture(currentPicture); // Set initial picture if editing
    } else {
      setNewPicture(null); // Reset for adding a new teacher
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewPicture(imageUrl); // Update local state with the new picture URL
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Teacher" : "Add Teacher"}
        </h2>

        {/* If in edit mode and there's a current picture, display it */}
        {isEdit && currentPicture && (
          <div className="mb-4">
            <label className="block font-semibold mb-1">Current Picture:</label>
            <img
              src={newPicture || currentPicture} // Use new picture if available
              alt="Teacher Profile"
              className="w-20 h-20 rounded-full mb-4"
            />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block">Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("username")}
                required
              />
            </div>
            <div>
              <label className="block">Email</label>
              <input
                type="email"
                className="w-full p-2 border rounded"
                {...register("email")}
                required
              />
            </div>
            <div>
              <label className="block">Phone</label>
              <input
                type="tel"
                className="w-full p-2 border rounded"
                {...register("phone")}
                required
              />
            </div>
            <div>
              <label className="block">Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                {...register("password")}
                required
              />
            </div>
            <div>
              <label className="block">Location</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("location")}
                required
              />
            </div>
            <div>
              <label className="block">DOB</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("dob")}
                required
              />
            </div>
            <div>
              <label className="block">Gender</label>
              <select
                className="w-full p-2 border rounded"
                {...register("gender")}
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div>
              <label className="block">Joining Date</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                {...register("joining_date")}
                required
              />
            </div>
            <div>
              <label className="block">Job Nature</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("job_nature")}
                required
              />
            </div>
            <div>
              <label className="block">Combinations</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("combinations")}
                required
              />
            </div>
            <div>
              <label className="block">CNIC</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                {...register("cnic")}
                required
              />
            </div>

            <div>
            <label className="block">
                {isEdit ? "Upload New Picture" : "Upload Picture"}
              </label>
              <input
                type="file"
                className="w-full p-2 border rounded"
                {...register("picture")}
                required={!isEdit} // Only required in add mode
                onChange={handlePictureChange} // Update picture on change
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => {
                reset(); // Reset form when closing modal
                setIsModalOpen(false);
              }}
              className="px-4 py-2 bg-red-500 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeacherModal;
